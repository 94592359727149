<template>
  <v-tooltip right>
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on">mdi-information </v-icon>
    </template>
    <span>{{ fieldAttributes.associatedOption[value] }}</span>
  </v-tooltip>
</template>
<script>
export default {
  props: ["result", "fieldAttributes", "field", "value"],
};
</script>